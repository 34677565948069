<template>
  <AppDialog :is-open="showCreateHandle" max-width="500" @onCloseDialog="closeDialog">
    <template slot="title">Create Contact Handle</template>
    <ValidationObserver ref="formObs" v-slot="{ handleSubmit }">
      <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <SearchSelectClient v-model="clients_id" rules="required" />
            </v-col>

            <v-col cols="12" md="6">
              <SelectGender v-model="sex" rules="required" />
            </v-col>

            <v-col cols="12" md="6">
              <VTextFieldWithValidation
                v-model="firstname"
                rules="required"
                label="Firstname"
                name="firstname"
                placeholder="Enter Firstname"
              />
            </v-col>

            <v-col cols="12" md="6">
              <VTextFieldWithValidation
                v-model="lastname"
                rules="required"
                label="Lastname"
                name="lastname"
                placeholder="Enter Lastname"
              />
            </v-col>

            <v-col cols="12" md="6">
              <VTextFieldWithValidation
                v-model="email"
                rules="required"
                label="E-Mail Address"
                name="email"
                placeholder="Enter E-Mail Address"
              />
            </v-col>

            <v-col cols="12" md="6">
              <VTextFieldWithValidation
                v-model="phone"
                rules="required"
                label="Phone"
                name="phone"
                placeholder="Enter Phone"
              />
            </v-col>

            <v-col cols="12" class="text-right">
              <v-btn color="default" class="mr-2" @click="closeDialog">Abbrechen</v-btn>
              <v-btn color="success" :disabled="pending" type="submit">Create</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
  </AppDialog>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import SearchSelectClient from '@/components/Clients/SearchSelectClient'
import SelectGender from '@/components/UI/SelectGender'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import AppDialog from '@/components/UI/AppDialog'

export default defineComponent({
  name: 'CreateShortHandle',
  components: {
    SearchSelectClient,
    SelectGender,
    VTextFieldWithValidation,
    AppDialog,
  },
  props: {
    value: {
      type: [
        String,
        Boolean,
      ],
      default: false,
    },
  },
  setup(props, { root: { $store }, emit }) {
    const showCreateHandle = computed({
      get: _ => !!props.value,
      set: _ => {
        emit('input', null)
      },
    })

    const formObs = ref(null)

    // store
    const pending = computed(_ => $store.state.handle.pending)
    const errors = computed(_ => $store.state.handle.errors)
    const createHandle = payload => $store.dispatch('handle/createHandle', payload)
    const clearHandle = _ => $store.dispatch('handle/clearHandle')
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    const clearData = _ => {
      clearHandle()
      formObs.value?.reset()
    }

    const submitForm = _ => {
      if (pending.value) {
        return
      }

      createHandle({ type: 'CONTACT' })
        .then(({ handle, errors }) => {
          if (errors) {
            addToast({
              msg: 'Erstellung fehlgeschlagen',
              type: 'error',
            })
            return
          }

          addToast({
            msg: 'Handle erfolgreich erstellt',
            type: 'success',
          })

          emit('onHandleCreated', handle)
          clearData()
        })
        .catch(errors => {
          if (errors) {
            addToast({
              msg: 'Erstellung fehlgeschlagen',
              type: 'error',
            })
            return
          }
        })
    }

    onUnmounted(_ => {
      clearData()
    })

    const closeDialog = _ => {
      clearData()
      showCreateHandle.value = false
    }

    return {
      showCreateHandle,
      errors,
      pending,
      formObs,
      closeDialog,
      submitForm,
      ...mapFields('handle', [
        'handle.clients_id',
        'handle.firstname',
        'handle.lastname',
        'handle.phone',
        'handle.email',
        'handle.sex',
      ]),
    }
  },
})
</script>
